import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ShowcaseImage = () => {
  return (
    <div className="flex justify-center mt-6">
      <LazyLoadImage
        alt="introduction to Chart GPT"
        className="rounded-xl"
        src="https://res.cloudinary.com/dcqsukigi/image/upload/v1697622274/images/Image_1_ra2mqp.png"
        effect="blur"
      />
    </div>
  );
};

export default ShowcaseImage;
