import React from 'react'

const Hero = ({ toggleTheme }) => {
  return (
    <div>
      <section className="px-2 mt-10 pt-12">
        <div className="flex items-center justify-center gap-4">
          <div className="bg-slate-200 dark:bg-gray-900 flex items-center gap-[3px] px-4 py-4 rounded-full">
            <img
              src="https://res.cloudinary.com/dcqsukigi/image/upload/v1692783064/images/q6mzjg5danzrjufusczu.png"
              alt="dot_logo"
            />
            <h4 className="md:text-xl text-xs font-semibold txt">
              No longer lost in translation, your confidence is limitless⚡.
            </h4>
          </div>
        </div>
        <div className="md:text-center text-left justify-start mt-8 flex md:justify-center flex-col">
          <h1 className="font-semibold md:text-6xl text-2xl txt_case">
            Revolutionizing Communication️ <br /> With Intelligience
          </h1>
        </div>
        <div className="flex justify-center mt-5">
          <a
            href="https://docs.google.com/forms/d/1EQHllieLYkHVop8gwrm7SD5RZhJd7jUKyaxlwYhd-kg/viewform?edit_requested=true"
            className="font-semibold text-xl btn_show bg-[#00F78E] px-4 py-2 rounded-md duration-150 ease-in hover:opacity-60"
          >
            Join The Waitlist
          </a>
        </div>

        <div className="flex justify-center items-center my-8">
          <img
            src={
              toggleTheme
                ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696312810/images/DarkMood%20images/IllustrationDark07_jtsos0.png"
                : "https://res.cloudinary.com/dcqsukigi/image/upload/v1692783066/images/k1l5ddzac1i8mkgnpk9x.png"
            }
            alt="illustration"
          />
        </div>
      </section>
    </div>
  );
};

export default Hero