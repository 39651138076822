import React from 'react'

const CardFeatures = ({ toggleTheme }) => {
  return (
    <section className="mt-24">
      <div className="text-center flex justify-center my-10">
        <h1 className="flex txt_due items-center flex-row md:text-2xl lg:text-4xl text-xl font-semibold">
          Features that{" "}
          <img
            src="https://res.cloudinary.com/dcqsukigi/image/upload/v1692783065/images/arordj3ghm4eq7ls29mq.png"
            alt="magic"
          />{" "}
          will blow 💥 your mind
        </h1>
      </div>

      <article className="grid grid-cols-1 md:grid-cols-2 px-6 lg:grid-cols-3 gap-4">
        <div className="bg-slate-50 dark:bg-black px-2 rounded-xl py-2 shadow-md duration-200 ease-in hover:shadow-none">
          <div className="bg-slate-50 dark:bg-black my-5 mx-2">
            <a
              href="/"
              className={
                toggleTheme
                  ? "uppercase text-[#3CEF8D] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold  text-xl"
                  : "uppercase text-[#3CEF8D] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold bg-[#CAFCE1] shadow-md text-xl"
              }
            >
              personalized
            </a>
            <h5 className="text-xl font-semibold my-4">
              Personalized Language Learning Curriculum
            </h5>
            <div className="flex justify-center md:mt-12">
              <img
                src={
                  toggleTheme
                    ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696348093/images/DarkMood%20images/dark09_tbtwkh.png"
                    : "https://res.cloudinary.com/dcqsukigi/image/upload/v1693942934/personalized_spnehf.png"
                }
                alt="personalized.img"
                className="w-[25rem] h-[19rem]"
              />
            </div>
          </div>
        </div>

        <div className="bg-slate-50 dark:bg-black px-2 rounded-xl py-2 shadow-md duration-200 ease-in hover:shadow-none">
          <div className="bg-slate-50 dark:bg-black my-5 mx-2">
            <a
              href="/"
              className={
                toggleTheme
                  ? "uppercase text-[#A13CEF] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold  text-xl"
                  : "uppercase text-[#A13CEF] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold bg-[#E6CAFC] shadow-md text-xl"
              }
            >
              All digital
            </a>
            <h5 className="text-xl font-semibold my-4">Digital Human Tutors</h5>
            <p className="text-gray-400 md:text-[1.3rem] text-[1.1rem] mb-6">
              Rendo Ai harnesses the power of artificial intelligence and
              machine learning to provide a truly personalized learning
              experience.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              className="w-[20rem] rounded-lg"
              src="https://res.cloudinary.com/dcqsukigi/image/upload/v1693112267/human_evolution_qxbdwu.png"
              alt="phone graphic"
            />
          </div>
        </div>

        <div className="bg-slate-50 dark:bg-black px-2 rounded-xl py-2 shadow-md duration-200 ease-in hover:shadow-none lg:min-w-0 md:min-w-[92vw] lg:flex justify-center hidden  flex-col">
          <div className="bg-slate-50 dark:bg-black my-5 mx-2">
            <a
              href="/"
              className={
                toggleTheme
                  ? "uppercase text-[#3C99EF] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold  text-xl"
                  : "uppercase text-[#3C99EF] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold bg-[#CAE4FC] shadow-md text-xl"
              }
            >
              Communication
            </a>
            <h5 className="text-xl font-semibold my-4">
              Real-time Translation and Interpretation
            </h5>
            <p className="text-gray-400 md:text-[1.3rem] text-[1.1rem] mb-6">
              Rendo Ai offers real-time translation for text and spoken phrases,
              as well as camera translation for signs and menus. Communicate
              with anyone, regardless of their language.
            </p>
          </div>
          <div className={toggleTheme ? "dark" : "light"}>
            <img
              className="w-full"
              src={
                toggleTheme
                  ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696347588/images/DarkMood%20images/Illustration_sgqoax.png"
                  : "https://res.cloudinary.com/dcqsukigi/image/upload/v1692783065/images/jrimiq4cbz5ewiteqlsx.png"
              }
              alt="illustration"
            />
          </div>
        </div>
      </article>
    </section>
  );
};

export default CardFeatures