import { Link } from "react-router-dom";
import { BsTwitter, BsDiscord, BsLinkedin } from "react-icons/bs";

const Footer = ({ toggleTheme }) => {
  return (
    <section className="py-10 px-8">
      <div className="flex flex-col justify-between items-start md:items-center gap-8 md:flex-col lg:flex-row">
        <div className="flex items-center gap-8">
          <a href="/">
            <img
              src={
                toggleTheme
                  ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696313035/images/DarkMood%20images/Logo_wpp7qf.png"
                  : "https://res.cloudinary.com/dcqsukigi/image/upload/v1692783064/images/uym3tvrpgdemuf8rgx3s.png"
              }
              alt="logo"
            />
          </a>
          <div className="flex items-center gap-6">
            <Link to="https://twitter.com/Rendoai?t=b5TsMLNqoSWcpvGIwzoAgg&s=08">
              <BsTwitter size={25} />
            </Link>
            <Link to="/">
              <BsDiscord size={25} />
            </Link>
            <Link to="https://www.linkedin.com/company/rendo-ai/">
              <BsLinkedin size={25} />
            </Link>
          </div>
        </div>
        <div className="flex md:items-center flex-col lg:flex-row gap-6">
          <a
            href="#about"
            className="font-semibold text-sx  hover:text-gray-500 duration-200  ease-in "
          >
            Developers
          </a>
          <a
            href="/"
            className="font-semibold text-sx  hover:text-gray-500 duration-200  ease-in "
          >
            Company
          </a>
          <Link
            to="/about"
            className="font-semibold text-sx  hover:text-gray-500 duration-200  ease-in "
          >
            Contact
          </Link>
        </div>
        <a
          href="https://docs.google.com/forms/d/1EQHllieLYkHVop8gwrm7SD5RZhJd7jUKyaxlwYhd-kg/viewform?edit_requested=true"
          className="font-bold dark:bg-black text-sx border-2  px-6 py-3 rounded-full duration-150 ease-in hover:opacity-60"
        >
          Join The Waitlist
        </a>
      </div>
    </section>
  );
};

export default Footer;
