import React, { useState, useEffect } from 'react'
import Navbar from './Navbar'
import { BiSun } from "react-icons/bi";
import { BsFillMoonStarsFill } from "react-icons/bs";
import Footer from './Footer';
import { HiOutlineArrowNarrowRight } from "react-icons/hi"



export default function About() {

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const systemTheme = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(systemTheme.matches);

    const themeChangeHandler = (e) => {
      setIsDarkMode(e.matches);
    };

    systemTheme.addListener(themeChangeHandler);

    return () => {
      systemTheme.removeListener(themeChangeHandler);
    };
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`Home ${isDarkMode ? "dark" : "light"}`}>
      <div className="absolute items-center flex top-3 right-16 md:top-20 md:right-11 xl:top-20 xl:right-20">
        <button
          onClick={toggleTheme}
          className={
            isDarkMode
              ? "bg-[#1A1A1A] opacity-60 rounded p-[3px]"
              : " md:text-black lg:text-black text-white opacity-60 rounded p-[3px]"
          }
        >
          {isDarkMode ? <BiSun size={20} /> : <BsFillMoonStarsFill size={20} />}
        </button>
      </div>
      <section className="md:px-6 md:pt-4 rounded-xl">
        <Navbar toggleTheme={isDarkMode} />
        <div className="py-8">
          <article className="md:flex md:justify-center">
            <main>
              <div className="px-12" id="about">
                <h2 className="text-3xl  font-semibold dark:text-white">
                  About Rendo
                </h2>
                <p className="text-[16px] md:text-[18px] pt-3 w-full md:w-[25rem]">
                  Rendo is an innovative and dynamic startup that is
                  transforming the landscape of language barriers and
                  communication challenges
                </p>
              </div>
              <div className="flex flex-col md:flex-row md:items-center px-6 md:gap-8">
                <div>
                  <img
                    src="https://res.cloudinary.com/dcqsukigi/image/upload/v1696689570/images/DarkMood%20images/image_1_mfwx72.png"
                    alt="logo"
                    className="w-[30rem] pb-8"
                  />
                </div>
                <div className="px-6 md:px-0">
                  <h2 className="text-3xl  font-semibold dark:text-white">
                    Our Mission
                  </h2>
                  <p className="text-[16px] md:text-[18px] pt-3 md:w-[25rem]">
                    We aim to create a global community where individuals from
                    different linguistic backgrounds can engage effortlessly,
                    breaking down language barriers and fostering unity through
                    meaningful connections.
                  </p>
                </div>
              </div>
              <div className="py-8 px-12">
                <h2 className="text-3xl  font-semibold dark:text-white">
                  Our Vision
                </h2>
                <p className="text-[16px] md:text-[18px] pt-3 md:w-[30rem] w-full">
                  To become the leading and go-to platform when it comes to
                  fostering and promoting meaningful collaboration and
                  relationships amongst people from different cultures and
                  linguistic backgrounds across Africa and beyond.
                </p>
              </div>
              <div className="py-8 px-12 md:ml-64">
                <h2 className="text-3xl  font-semibold dark:text-white">
                  Why Choose Us
                </h2>
                <p className="text-[16px] md:text-[18px] pt-3 lg:w-[40rem] w-full">
                  At Rendo, we are more than just a startup; we are a movement
                  dedicated to reshaping communication norms. With a passionate
                  team and a commitment to innovation, we are excited to pioneer
                  a future where language is no longer a barrier but a bridge to
                  understanding, collaboration, and progress.
                </p>
              </div>
              <article
                className="flex flex-col md:flex-row md:items-center px-6 md:justify-between my-4"
                id="contact"
              >
                <div className="px-6 md:px-0">
                  <h2 className="text-3xl  font-semibold dark:text-white">
                    Contact Us
                  </h2>
                  <p className="text-[16px] md:text-[18px] pt-3 md:w-[30rem] mb-4">
                    Connect with us today and be a part of the language
                    revolution. Together, let's build a world where every word
                    spoken becomes a universal language understood by all.
                  </p>
                  <small className="font-bold text-[14px] md:text-[15px]">
                    For inquiries, partnerships, or to learn more, contact us at{" "}
                    <br></br>
                    <a href="/" className="p-[1px] underline">
                      @rendolangai@gmail.com
                    </a>
                  </small>
                </div>
                <div className="px-6 md:px-0 mt-8 md:mt-0">
                  <h2 className="text-3xl  font-semibold dark:text-white">
                    Subscribe
                  </h2>
                  <p className="text-[18px] pt-3">To get updates from us!</p>
                  <div className="flex items-center gap-3 mt-2">
                    <input
                      type="text"
                      className="bg-gray-200 border border-gray-100 text-black rounded-sm focus:outline-none px-[5px] py-[3px]"
                      placeholder="Enter email address"
                    />
                    <button className="bg-[#00F78E] py-[3px] px-4 text-black">
                      <HiOutlineArrowNarrowRight size={25} />
                    </button>
                  </div>
                </div>
              </article>
            </main>
          </article>
        </div>
        <Footer toggleTheme={isDarkMode} />
      </section>
    </div>
  );
}
