const Node = ({ toggleTheme }) => {
  return (
    <section className="mx-6">
      <div
        className={`bg-[#FAFAFA] ${
          toggleTheme ? "dark" : "light"
        } dark:bg-black shadow-md ease-in hover:shadow-none duration-200 px-2 py-2 rounded-md`}
      >
        <div className="my-8 px-8">
          <h1 className="text-2xl font-semibold w-full">
            Language AI made for people
          </h1>
          <p className="md:text-[1.5rem] text-[1.08rem]  text-gray-400 my-4">
            Gain access to a vast library of language learning materials,
            including lessons,
            <br /> exercises, practice materials, and a comprehensive vocabulary
            builder.
          </p>
        </div>
        <div className={toggleTheme ? "dark" : "light"}>
          <img
            src={
              toggleTheme
                ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696312809/images/DarkMood%20images/FrameDark05_j6koft.png"
                : "https://res.cloudinary.com/dcqsukigi/image/upload/v1692783065/images/yr4xhazwajjwjb2is1jb.png"
            }
            className="w-full px-6 md:hidden"
            alt="frame"
          />
          <img
            src={
              toggleTheme
                ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696312810/images/DarkMood%20images/FrameDark04_qzedxj.png"
                : "https://res.cloudinary.com/dcqsukigi/image/upload/v1692783065/images/bu7z05w7aldvapum7grj.png"
            }
            className="hidden md:block w-full"
            alt="frame"
          />
        </div>
      </div>
    </section>
  );
};

export default Node;

// ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696312810/images/DarkMood%20images/FrameDark04_qzedxj.png"
// : "https://res.cloudinary.com/dcqsukigi/image/upload/v1692783065/images/bu7z05w7aldvapum7grj.png";
