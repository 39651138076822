import React from 'react'
import ShowcaseImage from './ShowcaseImage';

const Showcase = () => {
  return (
    <section className="px-2">
      <div className="flex items-center justify-center gap-4">
        <div className="bg-slate-200 dark:bg-[#1A1A1A] flex items-center gap-[3px] px-4 py-4 rounded-full">
          <img
            src="https://res.cloudinary.com/dcqsukigi/image/upload/v1692783064/images/q6mzjg5danzrjufusczu.png"
            alt="dot_logo"
          />
          <h4 className="md:text-xl text-xs font-semibold txt">
            No longer lost in translation, your confidence is limitless⚡.
          </h4>
        </div>
      </div>
      <div className="md:text-center text-left justify-start  mt-8 flex md:justify-center flex-col">
        <h1 className="font-semibold md:text-6xl text-2xl txt_case">
          Revolutionizing Communication️ <br /> With AI Brilliance
        </h1>
        <p className="md:text-2xl txt_cases text-[0.9rem]  px-2 text-gray-400 my-6">
          The world is a playground of opportunities, as you can stay connected
          with people from diverse cultures <br /> and backgrounds, embracing
          the beauty of global communication.
        </p>
      </div>
      <div className="flex justify-center mt-5">
        <a
          href="https://docs.google.com/forms/d/1EQHllieLYkHVop8gwrm7SD5RZhJd7jUKyaxlwYhd-kg/viewform?edit_requested=true"
          className="font-semibold text-xl btn_show bg-[#00F78E] px-4 py-2 rounded-md duration-150 ease-in hover:opacity-60"
        >
          Join The Waitlist
        </a>
      </div>
      <ShowcaseImage />
    </section>
  );
}

export default Showcase