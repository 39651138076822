import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar({toggleTheme}) {

   const [menuOpen, setMenuOpen] = useState(false);
  return (
    <section className="flex justify-center md:py-10 w-full">
      <nav className=" bg-gray-900  flex nav_bar md:rounded-xl px-4 text-white items-center justify-between">
        <div className="lg:mr-20 md:mr-2 sm:mr-1">
          <a href="/" className="title">
            <img
              src="https://res.cloudinary.com/dcqsukigi/image/upload/v1699045341/images/Logo_grrc4u.png"
              alt="logo"
              width={110}
            />
          </a>
        </div>
        <div className="main">
          <ul className={menuOpen ? "open" : ""}>
            <li className="px-4 py-2">
              <a href="/" className="font-bold text-xl">
                Home
              </a>
            </li>
            <li className="px-4 py-2">
              <a href="/" className="font-bold text-xl">
                Careers
              </a>
            </li>
            <li className="px-4 py-2">
              <Link
                to="/about"
                className="font-bold text-xl text-white hover:text-[#00F78E] active:text-[#00F78E] "
              >
                About Us
              </Link>
            </li>
            <li
              className={
                toggleTheme
                  ? "hidden md:block px-[6px] py-[6px] big  bg-black text-white mx-2 my-2 rounded-md duration-150 ease-in hover:opacity-60"
                  : "hidden md:block px-[6px] py-[6px] big  bg-white text-black mx-2 my-2 rounded-md duration-150 ease-in hover:opacity-60"
              }
            >
              <a
                href="https://docs.google.com/forms/d/1EQHllieLYkHVop8gwrm7SD5RZhJd7jUKyaxlwYhd-kg/viewform?edit_requested=true"
                className="font-bold text-xl"
              >
                Join The Waitlist
              </a>
            </li>
          </ul>
        </div>
        <div className="md:hidden md:bg-white px-[6px]  btn py-[6px] bg-[#2F2F2F] md:text-black mx-4 my-2 rounded-md duration-150 ease-in hover:opacity-60">
          <a
            href="https://docs.google.com/forms/d/1EQHllieLYkHVop8gwrm7SD5RZhJd7jUKyaxlwYhd-kg/viewform?edit_requested=true"
            className="font-semibold text-xl txt"
          >
            Join The Waitlist
          </a>
        </div>
        <button className="md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
          <img
            src="https://res.cloudinary.com/dcqsukigi/image/upload/v1692783064/images/llva0dwthwqrp2qcl7pi.png"
            alt="btn"
          />
        </button>
      </nav>
      <div></div>
    </section>
  );
}


