import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Showcase from "./Showcase";
import Card from "./Cards/Card";
import Node from "./Modal/Node";
import CardFeatures from "./Cards/CardFeatures";
import Hero from "./Cards/Hero/Hero";
import Footer from "./Footer";
import { BiSun } from "react-icons/bi";
import { BsFillMoonStarsFill } from "react-icons/bs";

function Home() {
 const [isDarkMode, setIsDarkMode] = useState(false);

 useEffect(() => {
   const systemTheme = window.matchMedia("(prefers-color-scheme: dark)");
   setIsDarkMode(systemTheme.matches);

   const themeChangeHandler = (e) => {
     setIsDarkMode(e.matches);
   };

   systemTheme.addListener(themeChangeHandler);

   return () => {
     systemTheme.removeListener(themeChangeHandler);
   };
 }, []);

 const toggleTheme = () => {
   setIsDarkMode(!isDarkMode);
 };

  return (
    <div className={`Home ${isDarkMode ? "dark" : "light"}`}>
      <div className="absolute items-center flex top-3 right-16 md:top-20 md:right-11 xl:top-20 xl:right-20">
        <button
          onClick={toggleTheme}
          className={
            isDarkMode
              ? "bg-[#1A1A1A] opacity-60 rounded p-[3px]"
              : " md:text-black lg:text-black text-white opacity-60 rounded p-[3px]"
          }
        >
          {isDarkMode ? <BiSun size={20} /> : <BsFillMoonStarsFill size={20} />}
        </button>
      </div>
      <section className="md:px-6 md:pt-4 rounded-xl">
        <Navbar toggleTheme={isDarkMode} />
        <div className="my-6"></div>
        <Showcase />
        <Card toggleTheme={isDarkMode} />
        <CardFeatures toggleTheme={isDarkMode} />
        <div className="my-6"></div>
        <Node toggleTheme={isDarkMode} />
        <div className="my-6"></div>
        <Hero toggleTheme={isDarkMode} />
        <div className="py-6"></div>
        <Footer toggleTheme={isDarkMode} />
      </section>
    </div>
  );
}

export default Home;
