import React from 'react'

export default function Card({ toggleTheme }) {
  return (
    <section className="mt-24">
      <div className="text-center flex justify-center my-10">
        <h1 className="flex items-center flex-row md:text-2xl lg:text-4xl text-xl font-semibold">
          Why Choose
          <img
            src="https://res.cloudinary.com/dcqsukigi/image/upload/v1692891129/images/aulgbd9hs2x5frat0xi2.png"
            alt="emoji"
            className="w-[2rem] lg:w-[3rem] mx-[3px]"
          />
          Rendo Ai
          <img
            src="https://res.cloudinary.com/dcqsukigi/image/upload/v1692891082/images/BG-icon_zsd2rd.png"
            alt="vector"
            className="w-[2rem] lg:w-[3rem] mx-[3px]"
          />
          ?
        </h1>
      </div>

      <article className="grid grid-cols-1 md:grid-cols-2 px-6 lg:grid-cols-3 gap-4">
        <div className="bg-slate-50 dark:bg-black px-2 rounded-xl py-2 shadow-md duration-200 ease-in hover:shadow-none">
          <div className="bg-slate-50 dark:bg-black my-5 mx-2">
            <a
              href="/"
              className={
                toggleTheme
                  ? "uppercase text-[#3CEF8D] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold  text-xl"
                  : "uppercase text-[#3CEF8D] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold bg-[#CAFCE1] shadow-md text-xl"
              }
            >
              personalize
            </a>
            <h5 className="text-xl font-semibold my-4">
              Personalized Language Learning
            </h5>
            <p className="text-gray-400 md:text-[1.3rem] text-[1.1rem] mb-6">
              Whether you're a beginner or an advanced learner, Rendo Ai tailors
              its curriculum to your proficiency level and learning style.
            </p>
          </div>
          <div className={toggleTheme ? "dark" : "light"}>
            <img
              className="w-full"
              src={
                toggleTheme
                  ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696312852/images/DarkMood%20images/IllustrationDark01_zwqysz.png"
                  : "https://res.cloudinary.com/dcqsukigi/image/upload/v1692783064/images/dqax2nawdjirqclpljua.png"
              }
              alt="illustration"
            />
          </div>
        </div>

        <div className="bg-slate-50  dark:bg-black px-2 rounded-xl py-2 shadow-md duration-200 ease-in hover:shadow-none">
          <div className="bg-slate-50 dark:bg-black my-5 mx-2">
            <a
              href="/"
              className={
                toggleTheme
                  ? "uppercase text-[#A13CEF] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold  text-xl"
                  : "uppercase text-[#A13CEF] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold bg-[#E6CAFC] shadow-md text-xl"
              }
            >
              AI-Superpower
            </a>
            <h5 className="text-xl font-semibold my-4">
              AI-Powered Personalization
            </h5>
            <p className="text-gray-400 md:text-[1.3rem] text-[1.1rem] mb-6">
              Rendo Ai harnesses the power of artificial intelligence and
              machine learning to provide a truly personalized learning
              experience.
            </p>
          </div>
          <div className={toggleTheme ? "dark" : "light"}>
            <img
              className="w-full"
              src={
                toggleTheme
                  ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696312852/images/DarkMood%20images/IllustrationDark02_twwcu6.png"
                  : "https://res.cloudinary.com/dcqsukigi/image/upload/v1692783064/images/a3goas0tt7ucqdbcdbcr.png"
              }
              alt="illustration"
            />
          </div>
        </div>

        <div className="bg-slate-50 dark:bg-black px-2 rounded-xl py-2 shadow-md duration-200 ease-in hover:shadow-none lg:min-w-0 md:min-w-[87vw] flex justify-center flex-col">
          <div className="bg-slate-50 dark:bg-black my-5 mx-2">
            <a
              href="/"
              className={
                toggleTheme
                  ? "uppercase text-[#3C99EF] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold  text-xl"
                  : "uppercase text-[#3C99EF] px-[4px] hover:shadow-none duration-200 ease-in-out py-[2px] font-semibold bg-[#CAE4FC] shadow-md text-xl"
              }
            >
              Communication
            </a>
            <h5 className="text-xl font-semibold my-4">
              Real-time Translation and Interpretation
            </h5>
            <p className="text-gray-400 md:text-[1.3rem] text-[1.1rem] mb-6">
              Rendo Ai offers real-time translation for text and spoken phrases,
              as well as camera translation for signs and menus. Communicate
              with anyone, regardless of their language.
            </p>
          </div>
          <div className={toggleTheme ? "dark" : "light"}>
            <img
              className="w-full"
              src={
                toggleTheme
                  ? "https://res.cloudinary.com/dcqsukigi/image/upload/v1696347588/images/DarkMood%20images/Illustration_sgqoax.png"
                  : "https://res.cloudinary.com/dcqsukigi/image/upload/v1692783065/images/jrimiq4cbz5ewiteqlsx.png"
              }
              alt="illustration"
            />
          </div>
        </div>
      </article>
    </section>
  );
}
